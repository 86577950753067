.why-choose-bct{
    background-color: #F3FFF7;
    background-repeat: no-repeat;
    background-position: bottom left;
}
@media screen and (max-width:767px)
{
    .why-choose-bct{
        background-image: none !important;
    }
}