/* .list {font-family: 'Kumbh Sans', sans-serif !important;} */
.intro-left{
    position: relative;
}
.intro-left p{
    color: white;
    background-color: var(--repla-rgba);
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 270px;
    text-align: center;
    font-weight: 600;
}
@media screen and (max-width:400px)
{
    .intro-left p{
        width: 230px;
        text-align: center;
        font-weight: 600;
    }
}