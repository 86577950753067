.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.project-box{
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.project-box .two{
    position: absolute;
    top: 0;
    width:100%;
    visibility:hidden;
    height:0%;
    /* background-color: rgba(0,0,0,0.3); */
    border-top: 0px solid rgba(0,0,0,0.3);
	border-left: 60px solid transparent;
	border-right: 60px solid transparent;
	
}
.project-button{
    position: absolute;
    bottom: 0%;
    width: 93%;
    overflow: hidden;
}
.project-button .project-button-effect{
    position: absolute;
    width:100%;
    height:0%;
    background-color: var(--repla);
    bottom:0
}
.project-box .project-button-circle{
    background-color: white;
    padding: 30px;
    position: absolute;
    right:-60px;
    top:-60px;
    border-radius:50%;
}
.project-box .project-button-circle span{
    position: relative;
    left:-11px;
    top:11px
}



.project-button .project-button-text-cover{
    position: relative;
    width: 100%;
    height: 100%;
}

.project-box:hover .two{
    border-top: 600px solid rgba(0,0,0,0.3);
    border-left: 1px solid transparent;
	border-right: 1px solid transparent;
    height:100%;
    visibility:visible;
}

.project-box:hover .project-button-effect{
    height: 100%;
}
.project-box:hover .project-button-text{
    color: white !important;
}
.project-box:hover .project-button-circle{
    top:-30px;
    right: -30px;
}

/* .slick-dots{
    padding: 20px 0 !important;
} */
.slick-dots li button::before{
    margin-top: 15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: rgb(23,25,30);
    border-radius:50%;
    content: '';
    text-align: center;
    opacity: .25;
}
