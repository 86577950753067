.gallery-page__single {
    position: relative;
    display: block;
}
.gallery-page__img {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
}
.gallery-page__img:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    background-color: rgba(102, 83, 232, 0.9);
    -webkit-transition: all 700ms ease;
    transition: all 700ms ease;
    -webkit-transform: translateY(-102%);
    transform: translateY(-102%);
    z-index: 1;
}
.gallery-page__img img {
    width: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.gallery-page__icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 2;
}
.gallery-page__icon a {
    height: 60px;
    width: 60px;
    background-color: #bf2e2e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 22px;
    color: #fff;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
    -webkit-transition: all 700ms ease;
    transition: all 700ms ease;
    z-index: 2;
}
.gallery-page__single:hover .gallery-page__img:before {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}
.gallery-page__single:hover .gallery-page__img img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}
.gallery-page__single:hover .gallery-page__icon a {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
}
.icon-plus-symbol{
    transform: translateY(-4px);
}
.gallery-page__single:hover .gallery-page__icon a {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
}

.gallery-page__icon a:hover {
    background-color: #fff;
    color: #bf2e2e;
}