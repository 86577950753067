.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.news-section{
    background-color: #FCFAE5
}
.news-slider{
    cursor: pointer;
}
.news-slider-image-box{
    position: relative;
    overflow: hidden;
}
.news-slider-image-box .tag{
    background-color: var(--secondary-color);
    color: white !important;
    position: absolute; 
    left: 20px;
    top:20px;
    border-radius:3px;
}
.news-slider-image-box .two{
    width: 100%;
    height: 10%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.3);
    visibility: hidden;
}
.news-slider .news-heading{
    color: black;
}
.news-slider:hover .two{
    visibility: visible;
    height: 100%;
}
.news-slider:hover .one{
    transform: scale(1.1);
}
.news-slider:hover .news-heading{
    color: var(--repla);
}

.slick-dots li button::before{
    margin-top: 18px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: var(--repla);
    border-radius:50%;
    content: '';
    text-align: center;
    opacity: .25;
}

.pagin .anticon{
    transform: translateY(-3px);
}

.ant-pagination-item{
    border-radius: 0px;
}
.ant-pagination-item:hover a {
    color: #ffffff !important;
}

.ant-pagination-item:hover {
    border-color: #b80000;
    transition: all 0.3s;
    background-color: #b80000;
}

.ant-pagination-item-active a {
    color: #fff !important;
}
.ant-pagination-item-active {
    font-weight: 500;
    background: #b80000;
    border-color: #b80000;
    
}


.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: #ffffff;
    border-color: #b80000;
    background: #b80000;
    border-radius: 0px;
}