.img{
    width: 20px;
    height: 20px;
}
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .custom-modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .custom-modal h2 {
    margin-top: 0;
  }
  
  .custom-modal button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    cursor: pointer;
  }
  .shareIcon {
    width: 50px;
    height: 50px;
  }
  .shareDiscord {
    width: 45px;
    height: 45px;
  }