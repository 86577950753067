.cardcss-product {
    position: relative;
    display: block;
    background-color: #f7f7f9;
    padding: 30px 30px 30px;
}
.cardcss-product .ant-card{
    border: none !important;
    margin-bottom: 10px;
}

.h4-css h5{
    margin-bottom: 0px;
}


.collapsecss .ant-collapse-extra{
    font-size: 18px;
    color: #777181;
}

.ant-collapse-item-active .ant-collapse-header-text{
    color: #bf2e2e !important;
}

.ant-collapse-item-active .anticon-plus-circle{
    color:#bf2e2e;
}

.cardcss-product .ant-space {
    width: 100%;
    justify-content: space-between;
}

.cardcss-product .ant-card-body{
    padding: 5px !important;
}


.collapsecss .ant-collapse-header-text{
    font-size: 18px;
    font-weight: 400;
    color: #777181;
}

.collapsecss .ant-collapse-item{
    padding: 10px;
}

.collapsecss .ant-collapse{
    border: none;
    padding: 10px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.topText-faq .ant-input-affix-wrapper{
    border-radius: 8px !important;
}

.topText-faq .ant-input-affix-wrapper:focus{
    border-color: transparent !important;
    box-shadow: none !important;
    
}

.cardcss-product Button{
    border: none !important;
    box-shadow: none !important;
    height: 100%;
    display: block;
    outline: none!important;
    width: 100%;
    background-color: transparent !important;
}

.cardcss-product Button:after{
    border: none !important;
    box-shadow: none !important;
    outline: none!important;
}

.cardcss-product Button:active{
    border: none !important;
    box-shadow: none !important;
    outline: none!important;
}

.ant-space {
    flex-wrap: wrap;
}

.hoverEffect-pro{
    transition: all 500ms ease;
    background-color: transparent !important;
}

.hoverEffect-pro .ant-card-body{
    background-color: transparent !important;
}

.hoverEffect-pro:hover{
    background-color: #ffff !important;
    -webkit-box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 5%);
    color: var(--repla-black);
}

.bg-white{
    background-color: #ffff;
    -webkit-box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 5%);
    color: var(--repla-black);
}

.color-red{
    color: #bf2e2e;
}

.arrowhide{
    display: none;
}

.hoverEffect-pro:hover .arrowhide{
   display: block;
   color: #bf2e2e;
}


/* new css add */
.service-details__need-help {
    position: relative;
    display: block;
    padding: 50px 50px 42px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    z-index: 1;
    background-color: #000;
}

.service-details__need-help-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #000;
    mix-blend-mode: luminosity;
    opacity: 0.50;
    z-index: -1;
}

.service-details__need-help-icon {
    height: 85px;
    width: 85px;
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 29px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.service-details__need-help-icon img {
    width: auto;
}

.service-details__need-help-title {
    font-size: 26px;
    color: #ffffff;
    line-height: 31px;
    font-weight: 700;
}

.service-details__need-help-contact {
    position: relative;
    display: block;
    margin-top: 41px;
}

.service-details__need-help-contact p {
    font-size: 14px;
    color: #ffffff;
    margin: 0;
    line-height: 20px;
}

.service-details__need-help-contact a {
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.service-details__need-help-contact a span {
    font-weight: 400;
}

.service-details__download {
    position: relative;
    display: block;
    margin-top: 30px;
}

.service-details__download-btn {
    width: 100%;
    text-align: center;
}
.thm-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none !important;
    background-color: #bf2e2e;
    color: #fff !important;
    font-size: 16px;
    font-weight: 700;
    padding: 14px 40px 14px;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    overflow: hidden;
    z-index: 1;
}

.service-details__right {
    position: relative;
    display: block;
}

.service-details__img {
    position: relative;
    display: block;
}

.service-details__img img {
    width: 100%;
}

.service-details__content {
    position: relative;
    display: block;
    margin-top: 30px;
}

.service-details__content-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 20px;
}

.service-details__text-1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

h3{
    color: #000;
    margin: 0;
}

.service-details__text-1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}

.service-details__text-2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #bf2e2e;
    padding-top: 29px;
    padding-bottom: 28px;
}

.service-details__points {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 41px;
    margin-bottom: 41px;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.service-details__points li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    width: 100%;
    text-align: center;
}

.service-details__points li:before {
    position: absolute;
    top: 30px;
    bottom: 35px;
    right: 0;
    content: "";
    width: 1px;
    background-color: #e9e9ee;
}

.service-details__points-icon {
    height: 75px;
    width: 75px;
    background-color: #bf2e2e;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.service-details__points-icon span {
    font-size: 30px;
    color: #fff;
    position: relative;
    display: inline-block;
}

.service-details__points-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin-top: 23px;
    margin-bottom: 15px;
}
.service-details__points-text {
    font-size: 16px;
    line-height: 30px;
}

.service-details__points-two {
    position: relative;
    display: block;
}

.service-details__points-two-single {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.service-details__points-two-content {
    position: relative;
    display: block;
    background-color: #f7f7f9;
    padding: 34px 40px 36px;
}

.service-details__points-two-content h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 11px;
}
.service-details__points-two-content p {
    font-size: 16px;
    line-height: 30px;
    margin: 0px;
}

.service-details__benefits {
    position: relative;
    display: block;
    margin-bottom: 50px;
    margin-top: 20px;
}

.service-details__benefits-img {
    position: relative;
    display: block;
}

.service-details__benefits-img img {
    width: 100%;
}

.service-details__benefits-right {
    position: relative;
    display: block;
    margin-top: -6px;
}

.service-details__benefits-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
}

.service-betails__benefits-text-1 {
    font-size: 18px;
    color: #bf2e2e;
    font-weight: 700;
    line-height: 33px;
    margin: 0;
    padding-top: 25px;
    padding-bottom: 28px;
}

.service-details__benefits-points {
    position: relative;
    display: block;
}

.service-details__benefits-points li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.service-details__benefits-points li .icon {
    color: #bf2e2e;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.service-details__benefits-points li .text {
    margin-left: 15px;
}


.service-details__benefits-points li .text p {
    font-size: 16px;
    line-height: 30px;
    margin: 0px;
}

.service-details__benefits-points li + li {
    margin-top: 3px;
}



/* =============================== media querry ================================ */

@media (min-width: 1024px){
    
    .topText-faq .ant-input-group{
        width: 50%;
        margin: 0 auto;
    }
    .topText-faq .ant-input-group-addon{
        padding: .4rem !important;
    }
    .ant-space {
        flex-wrap: nowrap;
    }
    
}


@media (min-width: 1400px){
    .padding-30{
        padding-left: 45px !important;
    }
}


@media only screen and (max-width: 767px){
    .service-details__points li {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .rightSidefaq .ant-card-body {
        padding: 5px;
        padding-top: 0px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        padding: 0px !important;
    }
    .collapsecss .ant-collapse-header-text {
        font-size: 12px;
    }
}


.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.services{cursor: pointer;}
.services-arrow{font-size:3px;visibility: hidden;}
.services-active{background-color: white;}
.services-arrow-active{font-size:18px;visibility: visible}
.services:hover{background-color: white;}

.services:hover .services-arrow{font-size:18px;visibility: visible;}
.service-contact-box{background-position: center;}