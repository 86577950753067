.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.feedback-box{
    position: relative;
    border: 2px solid white;
    overflow: hidden;
}
.feedback-box .bg-img{
    position: absolute;
    top:-190px;
    left:-180px;
    width:180px;
    height: 190px;
}
.feedback-box:hover{
    border-color: var(--repla);
}
.feedback-box:hover .bg-img{
    top:0;
    left:0;
}