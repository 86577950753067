.feature-one {
    position: relative;
    display: block;
    padding: 120px 0 90px;
}
.feature-one__single {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.feature-one__img {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
}
.feature-one__img img {
    width: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.feature-one__title-box {
    position: absolute;
    left: 50px;
    bottom: 52px;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 5;
}

.feature-one__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
}

.feature-one__title a {
    color: #ffffff;
}

.feature-one__hover-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 50px 50px 40px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    opacity: 1;
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    z-index: 3;
}
.feature-one__icon {
    position: relative;
    display: block;
}

.feature-one__icon span {
    font-size: 66px;
    color: var(--repla);
    position: relative;
    display: inline-block;
}


.feature-one__img:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    background-color: rgba(27, 21, 37, 0.9);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    opacity: 1;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    z-index: 1;
}
.feature-one__hover-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 21px;
    margin-bottom: 20px;
}

.feature-one__hover-title a {
    color: #ffffff;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.feature-one__hover-text {
    font-size: 16px;
    color: #aea8b9;
    line-height: 30px;
}

.feature-one__learn-more {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 31px;
}

.feature-one__learn-more a {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    color: var(--repla);
    font-weight: 700;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}
.feature-one__learn-more a i {
    position: relative;
    padding-left: 10px;
    top: -3px;
}

.feature-one__single:hover .feature-one__img img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.feature-one__single:hover .feature-one__title-box {
    opacity: 0;
    visibility: hidden;
}

.feature-one__single:hover .feature-one__hover-content {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.feature-one__single:hover .feature-one__img:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}



@media only screen and (max-width: 767px){
    .feature-one__hover-content {
        padding: 0px 30px 10px;
    }
    .feature-one__hover-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .feature-one__hover-title {
        margin-top: 10px;
        margin-bottom: 14px;
    }
    .feature-one__learn-more {
        margin-top: 8px;
    }
    .feature-one__icon span {
        font-size: unset;
    }
}


@media only screen and (min-width: 768px) and (max-width: 991px){
    .feature-one__hover-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}


@media only screen and (min-width: 992px) and (max-width: 1199px){
    .feature-one__hover-content {
        padding: 0px 30px 10px;
    }
    .feature-one__learn-more {
        margin-top: 9px;
    }
}


@media only screen and (min-width: 1200px) and (max-width: 1400px){
    .feature-one__hover-content {
        padding: 20px 50px 40px;
    }
}