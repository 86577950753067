
.animation{
    transition-property:all;
    transition-timing-function:ease;
    transition-duration:0.5s;
}
.repla-button{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.repla-button .one{
    position: absolute;
    left: 0;
    top:0;
    height:0px;
    width: 100%;
}
.repla-button .two{
    position: relative;
}
.repla-button:hover .one{
    height: 100%;
}
.repla-button:hover .two{
    color: white !important;
}