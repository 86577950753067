.service-item {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.service-item .gallery-img {
    position: relative;
    height:400px;
    overflow: hidden;
    display: flex;justify-content: center;align-items: center;
}

.service-item .gallery-img img {
    width: 100%;
}
.service-item .gallery-description {
    position: absolute;
    width: 100%;
    height:100%;
    top: 0;
    left: 0;
    background: rgb(55, 52, 53, 0.7);
    transition: .5s;
    opacity:0;
}
.service-item .thumbnail{transition: .5s}
.service-item .gallery-img:hover .gallery-description {
    opacity: 1;

}
.service-item .gallery-img:hover .thumbnail{transform: scale(1.2,1.2);}