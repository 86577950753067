.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--begin);
}
.footer .icons{
    font-size: 21px;
}
.footer-text{
    /* color: white !important; */
}
.footer-text-hover:hover{
    color: var(--repla) !important;
    cursor: pointer;
}

.footer .ant-breadcrumb-separator{
    color: white;
}
.anticon .anticon-down{
    color: white !important;
}
