.profile-bg{
    background-size:cover;
    background-attachment:fixed;background-position:center;
}
.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}

.profile{position: relative;overflow: hidden;cursor: pointer;}
.profile .profile-info{visibility: hidden;}
.profile .two{width:100%;height:0%;background:rgba(0,0,0,0.8);position: absolute;left:0;bottom:0;}
.profile:hover .two{height:100%;}
.profile:hover .profile-info{visibility: visible;}

