
.we-stand__top-title {
    font-size: 200px;
    font-weight: 700;
    color: var(--repla-rgba-point-2);
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: center center;
    background-attachment: fixed;
}
.technology{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.technology .two{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.technology .two-2{
    display: none;
}

@media screen and (max-width:1120px)
{
    .we-stand__top-title {
        font-size: 150px;
    }
}
@media screen and (max-width:840px)
{
    .technology .two{
        display: none;
    }
    .technology .two-2{
        display: flex;
    }
}
@media screen and (max-width:465px)
{
    .we-stand__top-title {
        font-size: 120px;
    }
}
@media screen and (max-width:465px)
{
    .we-stand__top-title {
        font-size: 100px;
    }
}