/* .list {font-family: 'Kumbh Sans', sans-serif !important;} */
.about-services-left{
    position: relative;
}
.about-services-left .two{
    position: absolute;
    left: 5px;
    bottom: 5px;
}
@media screen and (max-width:400px)
{
    .intro-left p{
        width: 230px;
        text-align: center;
        font-weight: 600;

    }
}