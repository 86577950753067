.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@text-color-dark: rgb(117, 117, 117);@btn-primary-bg: #BE3130;@input-hover-border-color: #BE3130;@menu-dark-item-active-bg: #BE3130;@checkbox-color: #BE3130;@switch-sm-height: 20px;@switch-color: #BE3130;@progress-steps-item-bg: #BE3130;@calendar-item-active-bg: #BE3130;@calendar-item-active-bg: #BE3130;@calendar-column-active-bg: red;@primary-color: #BE3130;