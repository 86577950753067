.videoBox {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.videoBox .videoBox-inner {
    position: relative;
    height:70vh;
    overflow: hidden;
    display: flex;justify-content: center;align-items: center;
}

.videoBox .videoBox-inner img {
    width: 100%;
}
.videoBox .videoBox-button {
    position: absolute;
    width: 100%;
    height:100%;
    top: 0;
    left: 0;
    transition: .5s;
    background: rgb(55, 52, 53, 0.3)
}
.videoBox .videoBox-button>span{width:120px;height:120px;border:2px solid #ffff;border-radius:50%;
                                display: flex;justify-content: center;align-items: center;transition: .5s;}
.videoBox .videoBox-button>span>img{width:60px;height:60px;}
.videoBox .thumbnail{transition: .5s}
.videoBox .videoBox-inner:hover .videoBox-button {
    background: rgb(55, 52, 53, 0.7)
}
    .videoBox .videoBox-inner:hover  .videoBox-button>span{width:140px;height: 140px;}

.videoBox .videoBox-inner:hover .thumbnail{transform: scale(1.2,1.2);}