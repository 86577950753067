.shareBtn {
  width: 50px; /* Adjusted size for visibility */
  height: 50px; /* Adjusted size for visibility */
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px;
  cursor: pointer;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.custom-modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.custom-modal h2 {
  margin-top: 0;
}

.custom-modal button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background: #007bff;
  color: white;
  cursor: pointer;
}
.shareIcon {
  width: 50px;
  height: 50px;
}
.shareDiscord {
  width: 45px;
  height: 45px;
}