.services-one {
    background-color: #1b1525;
    background-position: center center;
}
.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.service-block{
    border: 1px solid #322d3b;;
}
.service-block:hover{
    background-color: white;
    transform: translateY(-10px);
}
.service-block:hover .one{
    color: black !important;
}