.animation {
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: 0.5s;
}
.transparent-bg {
  background-color: transparent;
}

.repla-navbar {
  display: flex;
  justify-content: center;
  transition-property: all;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition-timing-function: linear;
  transition-duration: 0.5s;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
}
.repla-navbar-move {
  top: -400px;
}
.repla-navbar > .repla-navbar-inner {
  width: 93%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.repla-navbar > .repla-navbar-inner > .repla-navbar-left {
  width: 140px;
  padding: 11px 0;
  transition-timing-function: linear;
  transition-duration: 0.5s;
}
.repla-navbar > .repla-navbar-inner > .repla-navbar-left img{
    transform: scale(1.3);
}
.repla-navbar .repla-navbar-left .one {
  width: 100%;
  transform: translateY(-7px);
}
.repla-navbar .repla-navbar-left .two {
  display: none;
}
.repla-navbar > .repla-navbar-inner > ul {
  display: flex;
  list-style-type: none;
}

.items .nav-arrow {
  transform: translateX(-12px);
  visibility: hidden;
  color: var(--repla) !important;
}
.items .item-name {
  padding: 0 5px;
  color: white;
}
.items .nav-arrowDown {
  transform: rotate(0deg);
}
.items:hover .nav-arrow {
  visibility: visible;
  transform: translateX(0px);
  color: white !important;
}
.nav-item:hover .nav-arrowDown {
  transform: rotate(180deg);
}
.repla-navbar .items {
  position: relative;
  padding: 0 0 0 30px;
  display: flex;
  align-items: center;
}
.repla-navbar .items .nav-item-menu {
  width: 200px;
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  list-style-type: none;
  padding: 20px 0;
  margin: 0;
  color: white;
  border-radius: 3px;
  transition-property: all;
  transition-timing-function: linear;
  transition-duration: 0.5s;
}
.items:hover .nav-item-menu {
  display: flex;
}
.nav-item-menu li {
  display: flex;
  margin-bottom: 2px;
}
.nav-item-menu > li > a {
  width: 100%;
  height: 100%;
  position: relative;
}
.nav-item-menu > li > a > span:last-child {
  position: relative;
  padding-left: 3px;
}
.nav-item-menu > li > a > span:first-child {
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
}
.nav-item-menu > li > a:hover .sp {
  width: 100%;
}
.arrowDown {
  transition-property: all;
  transition-timing-function: linear;
  transition-duration: 0.3s;
}

/* replanavbar small */
.repla-navbar-small {
  display: none;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0px;
  z-index: 100;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.repla-navbar-small-move {
  top: -400px;
}
.repla-navbar-small > .repla-navbar-inner {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.repla-navbar-small > .repla-navbar-inner > .repla-navbar-left {
  /* width:120px; */
  width: 140px;
  padding: 11px 0;
  display: flex;
  align-items: center;
  transition-timing-function: linear;
  transition-duration: 0.5s;
}
.repla-navbar-small > .repla-navbar-inner > .repla-navbar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.repla-navbar-small .sp-cover {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.repla-navbar-small .sp {
  width: 40px;
  border-bottom: 2px solid white;
  height: 0px;
  margin-bottom: 8px;
}
.repla-navbar-small .sp:last-child {
  margin-bottom: 0;
}
.repla-navbar-small .sp-2 {
  width: 30px;
}
.repla-navbar-small .sp-3 {
  width: 35px;
}

@media screen and (max-width: 970px) {
  .repla-navbar-small {
    display: flex;
  }
  .repla-navbar {
    transform: translateX(-1000px);
    align-items: flex-start;
    height: 100vh;
    width: auto;
  }
  .repla-navbar-trans {
    transform: translateX(0px);
  }
  .repla-navbar > .repla-navbar-inner {
    flex-direction: column;
    width: 100%;
  }
  .repla-navbar > .repla-navbar-inner > .repla-navbar-left {
    width: 100% !important;
    padding: 10x 0 !important;
    display: flex;
    align-items: flex-end;
    margin: 10px 0px;
  }
  .repla-navbar .repla-navbar-left .one {
    display: none;
  }
  .repla-navbar .repla-navbar-left .two {
    display: flex;
    cursor: pointer;
    margin-right: 10px;
  }
  .repla-navbar > .repla-navbar-inner > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
    padding: 0 !important;
  }
  .repla-navbar .items {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px;
    margin: 10px 0;
    margin: 0;
    width: 200px;
  }
  .repla-navbar > .repla-navbar-inner > ul > li:hover {
    background-color: var(--repla);
  }
  .repla-navbar .items {
    position: relative;
  }
  .repla-navbar .items .nav-item-menu {
    left: 200px;
    top: 0;
    z-index: 100;
  }
}
@media screen and (max-width: 550px) {
  .repla-navbar-small > .repla-navbar-inner > .repla-navbar-left {
    /* width:90px; */
    width: 120px;
  }
}
.repla-navbar-container{
  z-index: 1001;
}