.project-details {
    position: relative;
    display: block;
    padding: 120px 0 0px;
}

.project-details__top {
    position: relative;
    display: block;
}

.project-details__img-box {
    position: relative;
    display: block;
    padding-bottom: 81px;
}

.project-details__img-box img {
    width: 100%;
}
.project-details__details-box {
    position: absolute;
    bottom: 51px;
    left: 50px;
    right: 50px;
}

.project-details__details {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 7%);
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 7%);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 30px 85px 35px;
}
.list-unstyled {
    list-style: none;
}

.project-details__details li {
    position: relative;
    display: block;
}

.project-details__details li:not(:last-child):before {
    position: absolute;
    top: 10px;
    bottom: 5px;
    right: -66px;
    content: "";
    width: 1px;
    background-color: #e9e9ee;
}

.project-details__details-content {
    position: relative;
    display: block;
}

.project-details__details-title {
    font-weight: 400;
    line-height: 26px;
    font-size: 16px;
}

.project-details__details-name {
    font-weight: 700;
    color: #1b1525;
    line-height: 30px;
    font-size: 16px;
}

.project-details__content-one {
    position: relative;
    display: block;
}

.project-details__content-one-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 30px;
}

.project-details__content-one-text {
    font-size: 16px;
    line-height: 30px;
}

.project-details__content-two {
    position: relative;
    display: block;
    margin-top: 52px;
    margin-bottom: 51px;
}

.project-details__content-two-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 32px;
}

.project-details__content-two-text {
    font-size: 16px;
    line-height: 30px;
}

.project-details__points-img {
    position: relative;
    display: block;
}

.project-details__points-img img {
    width: 100%;
}

.project-details__points {
    position: relative;
    display: block;
    margin-left: 30px;
    margin-top: 12px;
}

.project-details__points li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.project-details__points li .icon {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.project-details__points li .icon span {
    font-size: 16px;
    color: rgb(187,10,30);
}

.project-details__points li .text {
    margin-left: 15px;
}

.project-details__points li .text p {
    font-size: 18px;
    color: #1b1525;
}

.project-details__content-three {
    position: relative;
    display: block;
    margin-top: 51px;
}

.project-details__content-three-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 32px;
}

.project-details__content-three-text {
    font-size: 16px;
    line-height: 30px;
}

.projectc-details__pagination-box {
    position: relative;
    display: block;
    text-align: center;
    border-top: 1px solid #e9e9ee;
    border-bottom: 1px solid #e9e9ee;
    padding: 35px 0;
    margin-top: 113px;
    margin-bottom: 30px;
}

.projectc-details__pagination {
    position: relative;
    display: block;
}

.projectc-details__pagination li.next {
    float: left;
    position: relative;
}
.projectc-details__pagination li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.projectc-details__pagination li a {
    font-size: 14px;
    color: #777181;
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.projectc-details__pagination li.next .content {
    text-align: left;
}
.projectc-details__pagination li .content {
    position: relative;
    display: block;
    top: -6px;
}

.projectc-details__pagination li span {
    font-size: 14px;
    line-height: 14px;
}

.projectc-details__pagination li p {
    color: #1b1525;
    font-weight: 700;
    line-height: 18px;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.projectc-details__pagination li.previous {
    position: relative;
    float: right;
}

.projectc-details__pagination li.previous .content {
    text-align: right;
}

.projectc-details__pagination li.previous i {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: #f7f7f9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    color: rgb(187,10,30);
    font-size: 12px;
    margin-left: 20px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 1;
}


.projectc-details__pagination li.next i {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: #f7f7f9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    color: rgb(187,10,30);
    font-size: 12px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    margin-right: 20px;
    z-index: 1;
}

.project-details__points li + li {
    margin-top: 6px;
}
p{
    margin-bottom: 0px;
}
.project-details__details-name a {
    color: #1b1525;
}

.icon span{
    line-height: 10px;
}

.projectc-details__pagination li a:hover {
    color: rgb(187,10,30);
}

.projectc-details__pagination li a:hover i {
    color: #ffffff;
    background-color: rgb(187,10,30);
}


@media only screen and (max-width: 767px){
    .project-details__details-box {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .project-details__details {
        flex-direction: column;
        align-items: baseline;
        padding: 30px 30px 35px;
    }
    .project-details__details li:before {
        display: none;
    }
    .project-details__details li+li {
        margin-left: 0;
        margin-top: 11px;
    }
    .projectc-details__pagination li.next {
        float: none;
    }
    .projectc-details__pagination li {
        justify-content: center;
    }
    .projectc-details__pagination li.previous {
        float: none;
        margin-top: 25px;
    }
    .project-details__points {
        margin-left: 0;
        margin-top: 41px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .project-details__details {
        padding: 30px 25px 35px;
    }
    .project-details__points {
        margin-left: 0;
        margin-top: 41px;
    }
    .project-details__details li:before {
        right: -25px !important;
    }
    .project-details__details li+li {
        margin-left: 50px;
    }
}


@media only screen and (min-width: 992px) and (max-width: 1199px){
    .project-details__details li:before {
        right: -33px !important;
    }
}