.blog-two {
    position: relative;
    display: block;
    padding: 0 0 183px;
}
.section-title {
    position: relative;
    display: block;
    margin-top: 30px;
    margin-bottom: 49px;
}
.section-title__tagline-box {
    position: relative;
    display: inline-block;
}
.section-title__tagline {
    position: relative;
    display: inline-block;
    color: #bf2e2e;
    font-size: 16px;
    font-weight: 700;
    padding-left: 16px;
}
.section-title-shape {
    position: absolute;
    top: -2px;
    left: 0;
}
.section-title-shape img {
    width: auto;
}
.section-title__title {
    margin: 0;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
}
.blog-two__single {
    position: relative;
    display: block;
}
.blog-two__img {
    position: relative;
    display: block;
    overflow: hidden;
}
.owl-carousel .owl-item img {
    display: block;
    width: 100%;
}
.blog-two__img img {
    width: 100%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}
.blog-two__tag {
    position: absolute;
    top: 40px;
    left: 40px;
    background-color: #bf2e2e;
    padding: 6px 20px 4px;
    z-index: 3;
}
.blog-two__tag p {
    font-size: 10px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    letter-spacing: 0.1em;
}
.blog-two__img > a {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(-27, 21, 37, 0.5);
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 30px;
    color: #bf2e2e;
    -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
    transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
    transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
    transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
}
.blog-two__img > a > span {
    position: relative;
}
.blog-two__img > a > span::before {
    content: '';
    width: 20px;
    height: 2px;
    background-color: #bf2e2e;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}
.blog-two__img > a > span::after {
    content: '';
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    width: 2px;
    height: 20px;
    background-color: #bf2e2e;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.blog-two__content {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 20px;
    padding: 0 59px;
}
.blog-two__meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.blog-two__meta li a {
    font-size: 14px;
    color: #bf2e2e;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}
.blog-two__meta li a i {
    color: #bf2e2e;
}
.blog-two__title {
    font-size: 30px;
    line-height: 35px;
    margin-top: 5px;
    margin-bottom: 13px;
    font-weight: 700;
}
.blog-two__title a {
    color: #1b1525;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}
.blog-two__single:hover .blog-two__img img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}
.blog-two__single:hover .blog-two__img > a {
    visibility: visible;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
}

.blog-two__single:hover .blog-two__title a {
    color: #bf2e2e;
}


/* ===== media query ===== */
@media only screen and (max-width: 767px){
    .section-title__title {
        font-size: 30px;
        line-height: 40px;
    }
    .blog-two__title {
        font-size: 25px;
    }
    .blog-two__content{
        padding: 0 20px;
    }
    
}