.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.service-box{padding:40px 30px; position: relative;overflow: hidden;background: #f5f5f5;cursor: pointer;}
.service-info{z-index:22;position: relative;top:0}
.service-box:hover{background-color: var(--repla);}
.service-box:hover .txt-white{color: white;}
.service-icon-white{display: none;}
.service-box:hover .service-icon-white{display: block;}
.service-box:hover .service-icon-black{display: none;}
.service-box:hover .service-title{color: white !important;}
.service-bg-image{position: absolute;right:0px;bottom: -160px;z-index:1;
                 visibility: hidden;
                 width:55px !important;
}
.service-box:hover  .service-bg-image{bottom: 5px;visibility: visible;right:10px;width:64px !important}


.moto{position: relative;overflow: hidden;cursor: pointer;}
.moto>img{position: absolute;left:0;top:0;}
.moto .three{padding: 50px;position: relative;}
.moto .two{width:100%;height:0%;background: var(--repla-rgba);position: absolute;left:0;bottom:0;}
.moto .icon-2{display: none;}
.moto:hover .two{height:100%;}
.moto:hover .one{transform: scale(1.5,1.5);}
.moto:hover .icon-2{display: flex;}
.moto:hover .icon-1{display: none;}

