.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.mission-box{
    position: relative;
    cursor: pointer;
}
.mission-box .two{
    position: absolute;
    left:0;
    bottom: 0;
    width:100%;
    height:0%;
    background-color: rgba(0,0,0,0.3);
}
.mission-button{
    position: absolute;
    bottom: -17%;
    width: 93%;
}
.mission-button .mission-button-effect{
    position: absolute;
    width:100%;
    height:0%;
    background-color: var(--repla);
    top:0
}
.mission-button .mission-button-text{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.mission-box:hover .two{
    height: 100%;
}

.mission-box:hover .mission-button-effect{
    height: 100%;
}
.mission-box:hover .mission-button-text{
    color: white !important;
}
