.services-two {
    position: relative;
    display: block;
    background-color: #1b1525;
    padding: 120px 0 90px;
    background-position: center center;
    overflow: hidden;
}
.service-two__shape-1 {
    width: 674px;
    height: 650px;
    background-color: #fff;
    opacity: 0.08;
    position: absolute;
    bottom: calc(100% - 325px);
    right: calc(100% - 340px);
    border-radius: 50%;
    mix-blend-mode: luminosity;
}
.service-two__shape-2 {
    width: 1146px;
    height: 1104px;
    background-color: #fff;
    opacity: 0.03;
    position: absolute;
    border-radius: 50%;
    bottom: calc(100% - 550px);
    right: calc(100% - 570px);
    mix-blend-mode: luminosity;
}

.section-title {
    position: relative;
    display: block;
    margin-top: -10px;
    margin-bottom: 49px;
}
.section-title__tagline-box {
    position: relative;
    display: inline-block;
}
.section-title__tagline {
    position: relative;
    display: inline-block;
    color: #bf2e2e;
    font-size: 16px;
    font-weight: 700;
    padding-left: 16px;
}
.section-title-shape {
    position: absolute;
    top: -2px;
    left: 0;
}

.section-title-shape img {
    width: auto;
}
.services-two .section-title__title {
    color: #fff;
}
.section-title__title {
    margin: 0;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
}
.services-two__single {
    position: relative;
    display: block;
    background-color: #fff;
    padding: 40px 40px 40px;
    text-align: center;
    margin-bottom: 30px;
    z-index: 1;
}
.services-two__icon {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 122px;
    width: 122px;
    background-color: #f7f7f9;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 1;
}
.services-two__icon span {
    position: relative;
    display: inline-block;
    font-size: 63px;
    color: #bf2e2e;
    -webkit-transition: all 500ms linear;
    transition: all 500ms linear;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    top: -7px;
}
.services-two__icon:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #8373f0;
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
    -webkit-transition-property: all;
    transition-property: all;
    opacity: 1;
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    border-radius: 50%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    z-index: -1;
}
.services-two__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 15px;
}
.services-two__text {
    font-size: 14px;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 13px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}
.services-two__btn-box {
    position: relative;
    display: block;
}
.services-two__btn {
    font-size: 14px;
    color: #1b1525;
    background-color: #f7f7f9;
    padding: 4px 0 4px;
    width: 100%;
}
.thm-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none !important;
    background-color: #bf2e2e;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 14px 40px 14px;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    overflow: hidden;
    z-index: 1;
}
.services-two__btn:after {
    background-color: #fff;
}
.thm-btn:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #1b1525;
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
    -webkit-transition-property: all;
    transition-property: all;
    opacity: 1;
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    z-index: -1;
}
.services-two__single:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #7968ed;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    opacity: 1;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    z-index: -1;
}

.services-two__title a {
    color: #1b1525;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    display: inline-block;
    max-width: min-content;
}
.services-two__single:hover .services-two__icon {
    background-color: #8373f0;
}
.services-two__single:hover .services-two__icon span {
    color: #ffffff;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}
.services-two__single:hover .services-two__title a {
    color: #ffffff;
}
.services-two__single:hover .services-two__text {
    color: #ffffff;
}
.services-two__single:hover .services-two__btn {
    color: #bf2e2e;
}
.services-two__single:hover .services-two__btn:after {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}
.services-two__single:hover:after {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}