

.animations{ transition-property:all;transition-timing-function:ease;transition-duration:3s;transition-delay:2s;}
.text-animation{ transition-property:all;transition-timing-function:ease;transition-duration:2s}

.home-slider{
    position: relative;
    width:100%;
    height: 110vh;
    overflow: hidden;
}
.home-slider .slider-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    filter: brightness(1.75);
    background-position: center;
    background-size: cover;
    transform: scale(1.5);
}
.home-slider .slider-bg-animation{
    animation: mymove 8s ease;
}
.home-slider .slider-left{
    position: absolute;
    left:120px;
    height: auto;
    opacity:0.4;
}
.home-slider .slider-left-animation{
    animation: mymove1 2s ease;
}
.home-slider .slider-right{
    position: absolute;
    right:-300px;
    bottom: 0;
    height: 80%;
    opacity:0.6;
}
.home-slider .slider-text{
    font-size: 30vw;
    font-weight: 700;
    color: rgba(255,255,255, 0);
    -webkit-text-stroke: 1px rgb(255,255,255, 0.1);
    position: absolute;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 100%;
}
.home-slider .slider-right-animation{
    right: 0;
}
.home-slider .text-one{
    transform: translateY(-60px);
}
.home-slider .text-one h1:first-child{
    font-size: 60px;
}
.home-slider .text-two{
    transform: translateY(60px);
}
.home-slider .text-one-animation{
    transform: translateY(0px);
}
.home-slider .text-two-animation{
    transform: translateY(0px);
}



@keyframes mymove {
    from {transform:scale(1);}
    to {transform: scale(1.5);}
  }
@keyframes mymove1 {
    from {left:-300px;}
    to {left: 120px;}
  }
@keyframes mymove2 {
    from {right:-700px;}
    to {right: 0;}
  }
.home-slider .slider-circle{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    transform: scale(1);
}
.home-slider .slider-circle:active{
    transform: scale(1.5);
}
@media screen and (max-width:1200px) {
    .home-slider .slider-left{
        width: 900px;
    }
}
@media screen and (max-width:1050px) {
    .home-slider .slider-left{
        display: none;
    }
}
@media screen and (max-width:991px) {
    .home-top-section-dots{
        margin:50px 0;
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width:620px) {
    /* .home-slider{
        height:100vh;
    } */
    .home-top-section-title{
        font-size: 50px !important;
    }
}
@media screen and (max-width:520px) {
    .home-top-section-title{
        font-size: 45px !important;
    }
}
@media screen and (max-width:320px) {
    .home-top-section-title{
        font-size: 35px !important;
    }
}
.mt_top{
    margin-top: 20vh;
}