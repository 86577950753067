.activeTab {
    color: rgba(42,108,159, 1) !important; /* Using !important to override any existing color styles */
    font-weight: bold;
}
.activeTab::before {
    content: ">";
}
.border-black{
    border-left: 1px solid rgb(93, 92, 92);
}
.z_i{
    z-index: 1000 !important;
    top: 22% !important;
}
.bg_grey{
    background: linear-gradient(rgba(233, 229, 229, 0.3), rgba(233, 229, 229, 1));
}
.fst_italic{
    font-style: italic;
}
/* Base class for the toc-container */
.toc-container {
    height: calc(100vh - 40px); /* Adjust if needed based on header/footer height */
    overflow-y: auto; /* Allows scrolling within the navigation if needed */
}
@media (max-width: 767.98px) { /* Bootstrap's breakpoint for small screens */
    .custom-width-sm {
      width: 100%;
    }
  }
  
  @media (min-width: 768px) { /* Bootstrap's breakpoint for medium screens */
    .custom-width-md-lg {
      width: 50%;
    }
  }

/* Sticky positioning for tablets and larger screens */
@media (min-width: 768px) {
    .toc-container {
        position: -webkit-sticky;
        position: sticky;
        top: 20%; /* Adjust based on your header or desired offset from the top */
    }
}
/* Relative positioning for mobile screens */
@media (max-width: 767px) {
    .toc-container {
        position: relative;
        top: 0;
    }
}
