@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap');
.about-page__counter {
    margin-bottom: 80px;
}
.counter-one {
    position: relative;
    display: block;
    background-color: #f7f7f9;
    padding: 120px 0 120px;
    z-index: 2;
}
.counter-one__bg {
    background-repeat: no-repeat;
    background-position: top center;
    background-color: #f7f7f9;
    background-blend-mode: luminosity;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.06;
}
.counter-one .container {
    position: relative;
    z-index: 10;
}

.counter-one__list {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.counter-one__single {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
    text-align: center;
    margin-bottom: 31px;
    padding: 0 45px;
}
.counter-one__icon {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 50%;
    background-color: white;
    -webkit-box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 5%);
    height: 75px;
    width: 75px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto 12px;
    z-index: 1;
}
.counter-one__icon::before {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    content: "";
    border-radius: 50%;
    background-color: rgb(187,10,30);
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
    transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
    z-index: -1;
}
.counter-one__icon span {
    position: relative;
    display: inline-block;
    color: rgb(187,10,30);
    font-size: 32px;
    -webkit-transition: all 500ms linear;
    transition: all 500ms linear;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transform: scale(1) rotateY(0deg);
    transform: scale(1) rotateY(0deg);
}
.counter-one__single:hover .counter-one__icon span {
    -webkit-transform: scale(0.9) rotateY(360deg);
    transform: scale(0.9) rotateY(360deg);
    color: #ffffff;
}
.counter-one__single:hover .counter-one__icon::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
/* *{
    
    font-family: 'Kumbh Sans', sans-serif !important;
} */
.counter-one__single h3 {
    font-size: 40px;
    line-height: 50px !important;
    color: rgb(187,10,30);
    font-weight: 400;
}
.counter-one__plus {
    font-size: 40px;
    line-height: 30px !important;
    color: rgb(187,10,30);
    position: relative;
    top: 5px;
}
.counter-one__title {
    font-size: 16px;
    color: #1b1525;
    font-weight: 700;
    line-height: 26px;
    padding-bottom: 15px;
}
.counter-one__single:not(:nth-child(4)):after {
    position: absolute;
    top: 47px;
    bottom: 52px;
    right: 0;
    content: "";
    width: 1px;
    background-color: #e9e9ee;
}

.custom-animate li:nth-child(1){
    visibility: visible; 
    animation-delay: 100ms; 
    animation-name: fadeInUp;
}

.custom-animate li:nth-child(2){
    visibility: visible; 
    animation-delay: 200ms; 
    animation-name: fadeInUp;
}

.custom-animate li:nth-child(3){
    visibility: visible; 
    animation-delay: 300ms; 
    animation-name: fadeInUp;
}

.custom-animate li:nth-child(4){
    visibility: visible; 
    animation-delay: 400ms; 
    animation-name: fadeInUp;
}


@media only screen and (max-width: 767px){
    .counter-one__single {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .counter-one__single {
        flex: 0 0 50%;
        max-width: 50%;
    }
}