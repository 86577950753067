.industry-cover{
    background-position:center;
    background-attachment:fixed;
    background-size:cover;
}

.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.industries{position: relative;background-color: rgb(16,13,23);}
.industries .iconWhite{display: none;}
.industries .three{width:50%;position:relative;width:100%;}
.industries .one{width:0%;position: absolute;left:0;top:0;height: 100%;}
.industries .two{width:0%;position: absolute;right:0;top:0;height: 100%;}

.industries:hover .iconWhite{display: flex;}
.industries:hover .iconBrand{display: none;}
.industries:hover .one{width: 50%;}
.industries:hover .two{width:50%}