.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.video-box{
    position: relative;
}
.video-box .two{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    visibility: hidden;
}
.video-box .two-text-1{
    font-size: 0px;
}
.video-box .two-text-2{
    font-size: 0px;
}
.video-box:hover .two{
    visibility: visible;
}
.video-box:hover .two-text-1{
    font-size: 20px;
}
.video-box:hover .two-text-2{
    font-size: 24px;
}